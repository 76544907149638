

























































































































































































































































































































































































































































































































































































































































































































































































































































































































































































.just_label_in_cards_override {
  .card label {
    text-transform: capitalize;
  }
  .build_with_rtl {
    .el-form-item__label {
      width: 100%;
    }
    .el-input__inner {
      text-align: right;
    }
  }
}
.working_together_wrapper {
  .ct-series-a .ct-line,
  .ct-series-a .ct-point {
    stroke: black;
  }
  .ct-series-b .ct-line,
  .ct-series-b .ct-point {
    stroke: #fe9d52;
  }
  .ct-series-c .ct-line,
  .ct-series-c .ct-point {
    stroke: #4198d7;
  }
  .ct-series-d .ct-line,
  .ct-series-d .ct-point {
    stroke: brown;
  }
  .ct-series-e .ct-line,
  .ct-series-e .ct-point {
    stroke: #7a61ba;
  }
  @media (min-width: 320px) and (max-width: 480px) {
    .ct-label.ct-horizontal.ct-end {
      font-size: 10px !important;
    }
  }
  .is_chart_wrapper {
    .ct-chart-line {
      width: 89% !important;
    }
  }
}
